<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("sla.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <div class="ml-auto">
          <v-btn color="primary" @click="addSla()">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td>
                    <span>
                      {{
                        $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </td>
                  <td>{{ item.service.name }}</td>
                  <td>{{ item.data.title }}</td>
                  <td style="min-width: 120px">
                    <template
                      v-if="$permission.$can('sla.update', ['user', 'service'])"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="editSla(item)"
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("sla.btn.edit") }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="
                        $permission.$can('model-field.delete', [
                          'user',
                          'service',
                        ])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteSla(item)"
                            class="ml-2"
                            :loading="loading.delete && sla.id === item.id"
                          >
                            <v-icon color="primary">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("sla.btn.delete") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <sla-form
      :dialog="dialog.form"
      :sla="sla"
      :working-times="workingTimes"
      :holidays="holidays"
      :services="services"
      @success="updateSlaSelected"
    ></sla-form>
    <confirm-delete-sla
      :dialog="dialog.delete"
      @confirm="confirmDeleteSla"
    ></confirm-delete-sla>
  </div>
</template>

<script>
import ConfirmDeleteSla from "./../commons/confirm.vue";
import SlaForm from "./form";
import ListMixin from "./../../mixins/list";
import { mapActions } from "vuex";
export default {
  mixins: [ListMixin],
  created() {
    this.setWorkingTimes();
    this.setHolidays();
    this.setServices();
  },
  data: () => ({
    path: "sla.list",
    dialog: {
      form: {
        display: false,
        title: null,
      },
      delete: {
        display: false,
        message: null,
      },
    },
    sla: {},
    holidays: [],
    workingTimes: [],
    services: [],
  }),
  methods: {
    addSla() {
      this.sla = {};
      this.dialog.form.display = true;
      this.dialog.form.title = this.$t("sla.new.title");
    },
    editSla(sla) {
      this.sla = sla;
      this.dialog.form.display = true;
      this.dialog.form.titme = this.$t("sla.edit.title");
    },
    deleteSla(sla) {
      this.sla = sla;
      this.dialog.delete.message = this.$t("sla.delete.confirm");
      this.dialog.delete.display = true;
    },
    updateSlaSelected(sla) {
      if (!this.sla.id) this.refreshItems();
      this.sla = sla;
    },
    async confirmDeleteSla() {
      try {
        this.loading.delete = true;
        await this.request({
          url: "sla.delete",
          method: "POST",
          data: {
            slaId: this.sla.id,
          },
          messages: {
            204: this.$t("sla.delete.success"),
            403: true,
            500: true,
            422: this.$t("error_codes.500"),
          },
        });
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("sla.fields.created.title"),
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160,
        },
        {
          text: this.$i18n.t("sla.fields.serviceId.title"),
          align: "start",
          sortable: true,
          value: "service.name",
          width: 150,
        },
        {
          text: this.$i18n.t("sla.fields.title.title2"),
          align: "start",
          sortable: true,
          value: "data.title",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120,
        },
      ];
    },
    async setWorkingTimes() {
      try {
        this.workingTimes = (
          await this.request({ url: "working-time.list" })
        ).data.data;
      } catch (error) {
        // empty
      }
    },
    async setHolidays() {
      try {
        this.holidays = (
          await this.request({ url: "holidays.list" })
        ).data.data;
      } catch (error) {
        // empty
      }
    },
    async setServices() {
      try {
        this.services = (
          await this.request({ url: "services.list?limit=50" })
        ).data.data;
      } catch (error) {
        // empty
      }
    },
    ...mapActions({ request: "request" }),
  },
  components: {
    SlaForm,
    ConfirmDeleteSla,
  },
};
</script>
