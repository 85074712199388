<template>
  <div>
    <sla-list></sla-list>
  </div>
</template>


<script>
import SlaList from './../../components/sla/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('sla.title'),
    };
  },
  components: {
    SlaList
  }
}
</script>