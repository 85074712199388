<template>
  <v-dialog
    v-model="dialog.display"
    fullscreen
    transition="dialog-bottom-transition"
    scrollable
    persistent
    no-click-animation
  >
    <v-card tile color="#e8eff5">
      <v-toolbar flat color="grey lighten-5" max-height="64">
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="secondary-font font-weight-medium text-h6">
            {{ dialog.title }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            text
            @click="saveSla()"
            :disabled="$v.form.$invalid"
            :loading="loading.save"
            >{{ $t("btn.save") }}</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row justify="center">
          <v-col md="5" cols="12">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header color="grey lighten-5">
                  <span class="secondary-font font-weight-medium">
                    {{ $t("sla.infos.title") }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-3">
                  <v-select
                    :items="services"
                    :label="$t('sla.fields.serviceId.title')"
                    :placeholder="$t('sla.fields.serviceId.placeholder')"
                    v-model="form.serviceId"
                    :error-messages="serviceIdErrors"
                    @input="delayTouch($v.form.serviceId)"
                    @blur="$v.form.serviceId.$touch"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                  <v-text-field
                    :label="$t('sla.fields.title.title')"
                    :placeholder="$t('sla.fields.title.placeholder')"
                    v-model="form.title"
                    :error-messages="titleErrors"
                    @input="delayTouch($v.form.title)"
                    @blur="$v.form.title.$touch"
                    autocomplete="off"
                  ></v-text-field>
                  <v-textarea
                    :label="$t('sla.fields.description.title')"
                    :placeholder="$t('sla.fields.description.placeholder')"
                    v-model="form.description"
                    rows="2"
                    no-resize
                    :error-messages="descriptionErrors"
                    @input="delayTouch($v.form.description)"
                    @blur="$v.form.description.$touch"
                  ></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="mt-3">
                <v-expansion-panel-header color="grey lighten-5">
                  <span class="secondary-font font-weight-medium">
                    {{ $t("sla.priority.title") }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-3">
                  <v-btn
                    depressed
                    @click="newPriorityAction()"
                    class="mb-3"
                    small
                    v-if="!newPriority"
                    >{{ $t("sla.btn.priority.new") }}</v-btn
                  >
                  <div v-if="!newPriority">
                    <v-simple-table dense v-if="form.targets.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left px-0">
                              {{ $t("sla.fields.priority.title.title") }}
                            </th>
                            <th class="text-right px-0" width="100px"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(target, index) in form.targets"
                            :key="`target-${index}`"
                          >
                            <td class="text-left px-0 py-2">
                              <div class="d-flex align-center">
                                <div
                                  :style="swatchStyle(false, target.color)"
                                />
                                <div class="ml-1">{{ target.title }}</div>
                              </div>
                            </td>
                            <td class="text-right px-0 p-2">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    color="primary"
                                    v-on="on"
                                    v-bind="attrs"
                                    small
                                    @click="editPriorityAction(index)"
                                    class="mr-1"
                                  >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("btn.edit") }}</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    color="danger"
                                    v-on="on"
                                    v-bind="attrs"
                                    small
                                    @click="deletePriorityAction(index)"
                                    class="mr-2"
                                  >
                                    <v-icon small>mdi-delete-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("btn.delete") }}</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <div v-else>
                    <v-card flat>
                      <v-card-text class="px-0">
                        <v-text-field
                          v-model="priorityForm.title"
                          :label="$t('sla.fields.priority.title.title2')"
                          :placeholder="
                            $t('sla.fields.priority.title.placeholder')
                          "
                          append-icon="mdi-text-short"
                          :error-messages="priorityTitleErrors"
                          @input="delayTouch($v.priorityForm.title)"
                          @blur="$v.priorityForm.title.$touch"
                        ></v-text-field>
                        <v-text-field
                          v-model="priorityForm.value"
                          :label="$t('sla.fields.priority.value.title')"
                          :placeholder="
                            $t('sla.fields.priority.value.placeholder')
                          "
                          append-icon="mdi-text-short"
                          :error-messages="priorityValueErrors"
                          @input="delayTouch($v.priorityForm.value)"
                          @blur="$v.priorityForm.value.$touch"
                        ></v-text-field>
                        <v-checkbox
                          :label="$t('sla.fields.priority.status.title')"
                          color="primary"
                          v-model="priorityForm.active"
                        ></v-checkbox>
                        <v-text-field
                          :label="$t('sla.fields.priority.color.title')"
                          :placeholder="
                            $t('sla.fields.priority.color.placeholder')
                          "
                          v-model="priorityForm.color"
                          autocomplete="off"
                          :error-messages="priorityColorErrors"
                          @input="delayTouch($v.priorityForm.color)"
                          @blur="$v.priorityForm.color.$touch"
                          readonly
                          v-mask="[
                            /#/,
                            /[0-9A-F]/,
                            /[0-9A-F]/,
                            /[0-9A-F]/,
                            /[0-9A-F]/,
                            /[0-9A-F]/,
                            /[0-9A-F]/,
                          ]"
                        >
                          <template v-slot:append>
                            <v-menu
                              v-model="colorPickerMenu"
                              top
                              nudge-bottom="105"
                              nudge-left="16"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on }">
                                <div
                                  :style="
                                    swatchStyle(
                                      colorPickerMenu,
                                      priorityForm.color
                                    )
                                  "
                                  v-on="on"
                                />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker
                                    mode="hexa"
                                    v-model="priorityForm.color"
                                    flat
                                  />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                        <v-text-field
                          :label="$t('sla.fields.priority.respondWithin.title')"
                          :placeholder="
                            $t('sla.fields.priority.respondWithin.placeholder')
                          "
                          v-model="priorityForm.respondWithin"
                          type="number"
                          min="0"
                          append-icon="mdi-timer-outline"
                          :error-messages="priorityRespondWithinErrors"
                          @input="delayTouch($v.priorityForm.respondWithin)"
                          @blur="$v.priorityForm.respondWithin.$touch"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('sla.fields.priority.resolveWithin.title')"
                          :placeholder="
                            $t('sla.fields.priority.resolveWithin.placeholder')
                          "
                          v-model="priorityForm.resolveWithin"
                          type="number"
                          min="0"
                          append-icon="mdi-timer-outline"
                          :error-messages="priorityResolveWithinErrors"
                          @input="delayTouch($v.priorityForm.resolveWithin)"
                          @blur="$v.priorityForm.resolveWithin.$touch"
                        ></v-text-field>
                        <v-select
                          :label="$t('sla.fields.priority.workingdate.title')"
                          :placeholder="
                            $t('sla.fields.priority.workingdate.placeholder')
                          "
                          v-model="priorityForm.workingTime"
                          append-icon="mdi-calendar-clock"
                          item-value="identifier"
                          :item-text="(item) => item.data.title"
                          :items="workingTimes"
                          clearable
                        ></v-select>
                        <v-select
                          :label="$t('sla.fields.priority.holidays.title')"
                          :placeholder="
                            $t('sla.fields.priority.holidays.placeholder')
                          "
                          v-model="priorityForm.holidays"
                          append-icon="mdi-calendar-clock"
                          item-text="data.title"
                          item-value="identifier"
                          :items="holidays"
                          clearable
                        ></v-select>
                      </v-card-text>
                      <v-card-text class="px-0 mb-5">
                        <div class="font-weight-bold mb-5">
                          <span class="secondary-font">{{
                            $t("sla.escalation.title")
                          }}</span>
                        </div>
                        <template
                          v-for="(
                            escalation, index
                          ) in priorityForm.escalations"
                        >
                          <v-card
                            outlined
                            :key="`escalation-${index}`"
                            class="mb-6 mx-0"
                          >
                            <v-card-text>
                              <div class="font-weight-bold mb-3 text-body-2">
                                <span class="secondary-font">
                                  <span v-if="index === 0">{{
                                    $t("sla.fields.priority.escalation.title")
                                  }}</span>
                                  <span v-else>{{
                                    $t(
                                      "sla.fields.priority.escalation.title2",
                                      {
                                        index: $utils.pad(index, 2),
                                      }
                                    )
                                  }}</span>
                                </span>
                              </div>
                              <v-text-field
                                v-model="priorityForm.escalations[index].time"
                                :label="
                                  $t(
                                    'sla.fields.priority.escalation.time.title'
                                  )
                                "
                                :placeholder="
                                  $t(
                                    'sla.fields.priority.escalation.time.placeholder'
                                  )
                                "
                                @input="
                                  delayTouch(
                                    $v.priorityForm.escalations.$each[index]
                                      .time
                                  )
                                "
                                @blur="
                                  $v.priorityForm.escalations.$each[index].time
                                    .$touch;
                                "
                                :error-messages="
                                  priorityEscalationTimeErrors[index]
                                "
                                type="number"
                                append-icon="mdi-timer-outline"
                                autocomplete="off"
                              ></v-text-field>
                              <v-text-field
                                v-model="
                                  priorityForm.escalations[index].contacts
                                "
                                :label="
                                  $t(
                                    'sla.fields.priority.escalation.contact.title'
                                  )
                                "
                                :placeholder="
                                  $t(
                                    'sla.fields.priority.escalation.contact.placeholder'
                                  )
                                "
                                class="mb-6"
                                @input="
                                  delayTouch(
                                    $v.priorityForm.escalations.$each[index]
                                      .contacts
                                  )
                                "
                                @blur="
                                  $v.priorityForm.escalations.$each[index]
                                    .contacts.$touch;
                                "
                                :error-messages="
                                  priorityEscalationContactErrors[index]
                                "
                                append-icon="mdi-contacts-outline"
                                autocomplete="off"
                                :hint="
                                  $t(
                                    'sla.fields.priority.escalation.contact.help'
                                  )
                                "
                                persistent-hint
                              ></v-text-field>
                              <div class="text-body-2 mb-1 font-weight-bold">
                                {{
                                  $t(
                                    "sla.fields.priority.escalation.template.title"
                                  )
                                }}
                              </div>
                              <v-textarea
                                v-model="
                                  priorityForm.escalations[index].template
                                "
                                :placeholder="
                                  $t(
                                    'sla.fields.priority.escalation.template.title'
                                  )
                                "
                                @input="
                                  delayTouch(
                                    $v.priorityForm.escalations.$each[index]
                                      .template
                                  )
                                "
                                @blur="
                                  $v.priorityForm.escalations.$each[index]
                                    .template.$touch;
                                "
                                :error-messages="
                                  priorityEscalationTemplateErrors[index]
                                "
                                append-icon="mdi-text-box-outline"
                                outlined
                                rows="5"
                                no-resize
                              ></v-textarea>
                              <div class="d-flex justify-end">
                                <v-btn
                                  outlined
                                  small
                                  color="danger"
                                  @click="deletePriorityEscalation(index)"
                                >
                                  <span>{{ $t("btn.delete") }}</span>
                                </v-btn>
                              </div>
                            </v-card-text>
                          </v-card>
                        </template>
                        <v-btn
                          color="primary"
                          small
                          outlined
                          @click="addPriorityEscalation()"
                          >{{ $t("sla.btn.add_escalation") }}</v-btn
                        >
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="cancelNewPriority()" text>
                          {{ $t("btn.cancel") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="savePriority()"
                          :disabled="$v.priorityForm.$invalid"
                        >
                          {{
                            priorityIdxSelected !== null
                              ? $t("btn.edit")
                              : $t("btn.add")
                          }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  required,
  helpers,
  maxLength,
  minValue,
} from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { mapActions } from "vuex";

const defaultForm = {
  serviceId: null,
  title: null,
  description: null,
  targets: [],
};

const defaultFormPriority = {
  title: null,
  value: null,
  color: "#FF0000",
  escalations: [],
  workingTime: null,
  holidays: null,
  resolveWithin: 0,
  respondWithin: 0,
  active: true,
};

const defaultFormEscalation = {
  hook: "EMAIL",
  time: null,
  contacts: null,
  template: null,
};

export default {
  mixins: [FormDelayTouchMixin],
  props: {
    sla: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    workingTimes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    holidays: {
      type: Array,
      default: function () {
        return [];
      },
    },
    services: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    form: Vue._.cloneDeep(defaultForm),
    panel: [0, 1],
    newPriority: false,
    colorPickerMenu: null,
    priorityForm: Vue._.cloneDeep(defaultFormPriority),
    priorityIdxSelected: null,
    preventCloseDialog: false,
    oldSlaId: null,
    loading: {
      save: false,
    },
  }),
  validations: {
    form: {
      title: { required, maxLength: maxLength(50) },
      description: { required, maxLength: maxLength(255) },
      serviceId: { required },
    },
    priorityForm: {
      title: { required, maxLength: maxLength(50) },
      value: {
        required,
        maxLength: maxLength(30),
        matchPriorityValue: (val) =>
          helpers.req(val) || /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(val),
      },
      color: {
        required,
        matchPriorityColor: (val) =>
          !helpers.req(val) || /#[A-F0-9]{6}/.test(val),
      },
      resolveWithin: { required, minValue: minValue(0) },
      respondWithin: { required, minValue: minValue(0) },
      escalations: {
        $each: {
          time: {
            required,
            matchTime: (val) => !helpers.req(val) || /^(-)?[0-9]+$/.test(val),
          },
          contacts: {
            required,
            matchEmails: (val) =>
              !helpers.req(val) || Vue.$utils.matchSeparatedEmail(val),
          },
          template: {
            required,
            maxLength: maxLength(5000),
          },
        },
      },
    },
  },
  methods: {
    initForm() {
      this.form = this._.cloneDeep(defaultForm);
    },
    initSlaForm() {
      const data = this.sla.data || {};
      if (this.$utils.hasProperty(this.sla, "service.id")) {
        this.form.serviceId = this.sla.service.id;
      }
      if (this.$utils.hasProperty(data, "title")) {
        this.form.title = data.title;
      }
      if (this.$utils.hasProperty(data, "description")) {
        this.form.description = data.description;
      }
      if (this.$utils.hasProperty(data, "targets")) {
        this.form.targets = data.targets;
      }
    },
    newPriorityAction() {
      this.priorityIdxSelected = null;
      this.newPriority = true;
    },
    editPriorityAction(index) {
      const { escalations, ...data } = this.form.targets[index];
      this.priorityForm = {
        ...data,
        escalations: this.parseEscalations(escalations),
      };
      this.priorityIdxSelected = index;
      this.newPriority = true;
    },
    deletePriorityAction(index) {
      this.form.targets.splice(index, 1);
    },
    savePriority() {
      const { escalations, ...data } = this.priorityForm;
      if (this.priorityIdxSelected !== null) {
        this.$set(this.form.targets, this.priorityIdxSelected, {
          ...data,
          escalations: this.formatEscalations(escalations),
        });
      } else {
        this.form.targets.push({
          ...data,
          escalations: this.formatEscalations(escalations),
        });
      }
      this.newPriority = false;
      this.resetPriorityForm();
    },
    cancelNewPriority() {
      this.newPriority = false;
      this.resetPriorityForm();
    },
    resetSlaForm() {
      this.form = this._.cloneDeep(defaultForm);
      this.$v.form.$reset();
    },
    resetPriorityForm() {
      this.priorityForm = this._.cloneDeep(defaultFormPriority);
      this.$v.priorityForm.$reset();
    },
    swatchStyle(menu, color) {
      return this.$utils.swatchStyle(menu, color);
    },
    addPriorityEscalation() {
      this.priorityForm.escalations.push(
        this._.cloneDeep(defaultFormEscalation)
      );
    },
    deletePriorityEscalation(index) {
      this.$v.priorityForm.escalations.$each[index].$reset();
      this.priorityForm.escalations.splice(index, 1);
    },
    formatEscalations(escalations) {
      const output = [];
      for (const escalation of escalations) {
        const { contacts, ...data } = escalation;
        output.push({ contacts: contacts.split(","), ...data });
      }
      return output;
    },
    parseEscalations(escalations) {
      const output = [];
      for (const escalation of escalations) {
        const { contacts, ...data } = escalation;
        output.push({ contacts: contacts.join(","), ...data });
      }
      return output;
    },
    closeDialog() {
      if (this.preventCloseDialog) {
        if (confirm(this.$t("sla.close_prevent_title"))) {
          this.dialog.display = false;
        }
        return;
      }
      // close dialog
      this.dialog.display = false;
    },
    async saveSla() {
      let url = "sla.create";
      let data = {};
      this.notify({ status: false });
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      if (this.sla.id) {
        data.slaId = this.sla.id;
        url = "sla.update";
      }

      const { serviceId, ...rest } = this.form;
      data.serviceId = serviceId;
      data.data = rest;

      this.loading.save = true;
      try {
        const response = await this.request({
          url: url,
          data: data,
          method: "POST",
          messages: {
            200: this.$t("sla.edit.success"),
            201: this.$t("sla.new.success"),
            400: true,
            404: this.$t("sla.errors.404"),
            422: (error) => {
              if (error.code === "E100") {
                return this.$t("services.errors.404");
              } else if (error.code === "E101") {
                return this.$t("form_error");
              }
              return this.$t("error_occured");
            },
            500: true,
          },
        });
        this.$emit("success", response.data);
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  watch: {
    "dialog.display": {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.sla.id != this.oldSlaId) {
            // reset form
            this.resetSlaForm();
            this.initSlaForm();
          }
        }
        // set old sla identifier
        this.oldSlaId = this.sla.id;
      },
    },
    form: {
      deep: true,
      handler() {
        this.preventCloseDialog = true;
      },
    },
  },
  computed: {
    serviceIdErrors() {
      const errors = [];
      if (!this.$v.form.serviceId.$dirty) return errors;

      !this.$v.form.serviceId.required &&
        errors.push(this.$t("sla.fields.serviceId.required"));

      return errors;
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("sla.fields.title.required"));

      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("sla.fields.title.maxLength", {
            max: this.$v.form.title.$params.maxLength.max,
          })
        );

      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;

      !this.$v.form.description.required &&
        errors.push(this.$t("sla.fields.description.required"));

      !this.$v.form.description.maxLength &&
        errors.push(
          this.$t("sla.fields.description.maxLength", {
            max: this.$v.form.description.$params.maxLength.max,
          })
        );

      return errors;
    },
    priorityTitleErrors() {
      const errors = [];
      if (!this.$v.priorityForm.title.$dirty) return errors;

      !this.$v.priorityForm.title.required &&
        errors.push(this.$t("sla.fields.priority.title.required"));
      !this.$v.priorityForm.title.maxLength &&
        errors.push(
          this.$t("sla.fields.priority.title.maxLength", {
            max: this.$v.priorityForm.title.$params.maxLength.max,
          })
        );

      return errors;
    },
    priorityValueErrors() {
      const errors = [];
      if (!this.$v.priorityForm.value.$dirty) return errors;

      !this.$v.priorityForm.value.required &&
        errors.push(this.$t("sla.fields.priority.value.required"));
      !this.$v.priorityForm.value.maxLength &&
        errors.push(
          this.$t("sla.fields.priority.value.maxLength", {
            max: this.$v.priorityForm.value.$params.maxLength.max,
          })
        );
      !this.$v.priorityForm.value.matchPriorityValue &&
        errors.push(this.$t("sla.fields.priority.value.invalid"));

      return errors;
    },
    priorityColorErrors() {
      const errors = [];
      if (!this.$v.priorityForm.color.$dirty) return errors;

      !this.$v.priorityForm.color.required &&
        errors.push(this.$t("sla.fields.priority.color.required"));
      !this.$v.priorityForm.color.matchPriorityColor &&
        errors.push(this.$t("sla.fields.priority.color.invalid"));

      return errors;
    },
    priorityRespondWithinErrors() {
      const errors = [];
      if (!this.$v.priorityForm.respondWithin.$dirty) return errors;

      !this.$v.priorityForm.respondWithin.required &&
        errors.push(this.$t("sla.fields.priority.respondWithin.required"));
      !this.$v.priorityForm.respondWithin.minValue &&
        errors.push(this.$t("sla.fields.priority.respondWithin.minValue"));

      return errors;
    },
    priorityResolveWithinErrors() {
      const errors = [];
      if (!this.$v.priorityForm.resolveWithin.$dirty) return errors;

      !this.$v.priorityForm.resolveWithin.required &&
        errors.push(this.$t("sla.fields.priority.resolveWithin.required"));
      !this.$v.priorityForm.resolveWithin.minValue &&
        errors.push(this.$t("sla.fields.priority.resolveWithin.minValue"));

      return errors;
    },
    priorityEscalationTimeErrors() {
      const errors = [];
      for (const index in this.$v.priorityForm.escalations.$each.$iter) {
        errors[index] = [];
        if (!this.$v.priorityForm.escalations.$each.$iter[index].time.$dirty)
          continue;
        !this.$v.priorityForm.escalations.$each.$iter[index].time.required &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.time.required")
          );
        !this.$v.priorityForm.escalations.$each.$iter[index].time.matchTime &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.time.invalid")
          );
      }
      return errors;
    },
    priorityEscalationContactErrors() {
      const errors = [];
      for (const index in this.$v.priorityForm.escalations.$each.$iter) {
        errors[index] = [];
        if (
          !this.$v.priorityForm.escalations.$each.$iter[index].contacts.$dirty
        )
          continue;
        !this.$v.priorityForm.escalations.$each.$iter[index].contacts
          .required &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.contact.required")
          );
        !this.$v.priorityForm.escalations.$each.$iter[index].contacts
          .matchEmails &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.contact.invalid")
          );
      }
      return errors;
    },
    priorityEscalationTemplateErrors() {
      const errors = [];
      for (const index in this.$v.priorityForm.escalations.$each.$iter) {
        errors[index] = [];
        if (
          !this.$v.priorityForm.escalations.$each.$iter[index].template.$dirty
        )
          continue;
        !this.$v.priorityForm.escalations.$each.$iter[index].template
          .required &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.template.required")
          );
        !this.$v.priorityForm.escalations.$each.$iter[index].template
          .maxLength &&
          errors[index].push(
            this.$t("sla.fields.priority.escalation.template.maxLength", {
              max: this.$v.priorityForm.escalations.$$each.$iter[index].template
                .$params.maxLength.max,
            })
          );
      }
      return errors;
    },
  },
};
</script>